import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ---------- Burger Menu

var header = document.querySelector(".header");

var burgerMenu = document.querySelector(".nav-burger-menu");
var nav = document.querySelector(".nav-desk");
let isRotated = false;

burgerMenu.addEventListener("click", () => {
  nav.classList.toggle("nav-menu-open");
  header.classList.toggle("nav-menu-open-height");

  var lineTop = burgerMenu.querySelector("#burger-menu-line-top");
  var lineBottom = burgerMenu.querySelector("#burger-menu-line-bottom");

  if (isRotated) {
    lineTop.style.transform = "rotate(0deg)";
    lineBottom.style.transform = "rotate(0deg)";
  } else {
    lineTop.style.transform = "rotate(45deg)";
    lineBottom.style.transform = "rotate(-45deg)";
  }
  isRotated = !isRotated;
});

var links = document.querySelectorAll(".link");

links.forEach((link) => {
  link.addEventListener("click", () => {
    nav.classList.remove("nav-menu-open");
  });
});

// ---------- Changement de langue

const frButton = document.getElementById("frButton");
const enButton = document.getElementById("enButton");

// Récupérer la langue stockée dans le stockage local du navigateur
const storedLanguage = localStorage.getItem("selectedLanguage");

// Si une langue est stockée, utilisez-la ; sinon, définissez la langue par défaut (français)
const initialLanguage = storedLanguage || "fr";
changeLanguage(initialLanguage);

frButton.addEventListener("click", () => {
  setLanguageAndStore("fr");
});

enButton.addEventListener("click", () => {
  setLanguageAndStore("en");
});

function setLanguageAndStore(language) {
  changeLanguage(language);
  // Stocker la langue dans le stockage local du navigateur
  localStorage.setItem("selectedLanguage", language);
}

function changeLanguage(language) {
  // Masquer tout le contenu
  const elements = document.querySelectorAll("[lang]");
  elements.forEach((element) => {
    element.style.display = "none";
  });

  // Afficher le contenu dans la langue sélectionnée
  const elementsInLanguage = document.querySelectorAll(`[lang="${language}"]`);
  elementsInLanguage.forEach((element) => {
    element.style.display = "block";
  });
}

// ---------- Progress
const progressBar = document.querySelector("progress");

gsap.to(progressBar, {
  value: 100,
  ease: "none",
  scrollTrigger: { scrub: 0.3 },
});

// ---------- Progressscroll animation ↓

var pictureBottom = document.querySelectorAll(".container-module");

pictureBottom.forEach((picture) => {
  gsap.from(picture, {
    scrollTrigger: {
      trigger: picture,
    },
    opacity: 0,
    y: 200,
    duration: 1,
  });
});

gsap.to("progress", {
  value: 100,
  ease: "none",
  scrollTrigger: { scrub: 0.3 },
});
